import { FormikProps } from 'formik';

import type { CommonWholesaleFormValues } from '../shared/types';

import { FieldsGroup } from '../../../../../../../components/FormGenerator/components/FormFieldsGroup';
import { GenFormConfig, GenFormField } from '../../../../../../../components/FormGenerator/FormGenerator';
import { FieldTypes } from '../../../../../../../types/field';
import {
  defaultDamageRuleGroup,
  enabledForBackfill,
  groupCode,
  groupNameTextAndPlanDescriptionGroup,
  groupType,
  mitchellGroup,
  modelPricingFieldsGroup,
  shouldCopyPricingPlan,
  wholesaleSourceGroupCode,
} from '../../../../../shared/fields';

type GetFormFieldsParams = Readonly<{
  formik: FormikProps<CommonWholesaleFormValues>;
}>;

export const getFormFields = ({ formik }: GetFormFieldsParams): GenFormConfig => {
  return {
    container: 'grid grid-cols-2 gap-4 mb-2',
    fields: [
      {
        type: FieldTypes.GROUP,
        fields: [enabledForBackfill],
        classes: {
          fieldsContainer: 'mb-2 col-span-2',
        },
      },
      groupType,
      groupCode,
      {
        type: FieldTypes.GROUP,
        fields: [
          groupNameTextAndPlanDescriptionGroup,
          {
            type: FieldTypes.GROUP,
            fields: [
              shouldCopyPricingPlan,
              {
                ...wholesaleSourceGroupCode,
                required: formik.values.shouldCopyPricingPlan === false,
              },
            ],
            classes: {
              fieldsContainer: 'gap-4 rounded border-dashed border-2 p-2 mt-3',
            },
          } as unknown as FieldsGroup,
        ],
        classes: {
          fieldsContainer: 'grid grid-cols-2 col-span-2 gap-4 mt-1',
        },
      },
      {
        type: FieldTypes.GROUP,
        fields: [defaultDamageRuleGroup, mitchellGroup, modelPricingFieldsGroup],
        classes: {
          fieldsContainer: 'grid grid-cols-3 col-span-2 gap-4 mb-2',
        },
      },
    ] as GenFormField[],
  };
};
