import { QueryFunction, useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import { useDebounceValue } from '../../../common/hooks/useDebounceValue';
import { fetchNextInspectClients, fetchWholesaleClients } from '../../../common/queries/clients/clients.api';
import { QueryKey } from '../../../constants/queries';
import { Client, ClientGroupType } from '../../../types/clients';
import { OptionsType } from '../../../types/field';
import { ClientNEXTInspectSubTab, ClientSubTab, ClientTab } from '../shared/types';

export interface UseClientListSearchConfig {
  setSearchClientInput: (str: string) => void;
  searchClientInput: string;
  searchClientOptions: OptionsType[];
  isSearchClientFetching: boolean;
}

export function useClientListSearch(tab: ClientTab | ClientGroupType, clientSubTab?: ClientSubTab): UseClientListSearchConfig {
  const [searchClientInput, setSearchInput] = useState<string>('');
  const [isSearchClientFetching, setSearchClientIsFetching] = useState<boolean>(false);
  const debouncedSearch = useDebounceValue(searchClientInput);

  const groupType = tab === ClientTab.Wholesale ? ClientGroupType.Wholesale : ClientGroupType.NextInspect;

  const queryFn: QueryFunction<OptionsType[]> = async ({ signal }) => {
    let items: Array<Partial<Client>> | undefined = undefined;

    if (tab === ClientTab.NextInspect) {
      const response = await fetchNextInspectClients({
        subTab: clientSubTab!,
        searchTerm: debouncedSearch,
        offset: 0,
        limit: 100,
        signal,
      });
      items = response.items;
    } else if (tab === ClientGroupType.Wholesale) {
      const response = await fetchWholesaleClients({
        searchTerm: debouncedSearch,
        offset: 0,
        limit: 100,
        signal,
      });
      items = response.items;
    }

    if (!items) {
      return [];
    }

    setSearchClientIsFetching(false);
    return items.map(({ planId, groupCode, groupName }) => ({
      label:
        (tab === ClientTab.NextInspect && clientSubTab === ClientNEXTInspectSubTab.ACTIVE_MANHEIM) || tab === ClientGroupType.Wholesale
          ? `${groupCode!} - ${planId!} - ${groupName!}`
          : `${groupCode!} - ${groupName!}`,
      value: groupCode!,
    }));
  };

  const { data, isFetching } = useQuery<OptionsType[]>({
    queryKey: [QueryKey.FETCH_CLIENTS, debouncedSearch, groupType],
    queryFn,
    cacheTime: 0,
    enabled: Boolean(debouncedSearch),
  });

  const options = data && !isFetching ? data : [];

  return {
    setSearchClientInput: (str: string): void => {
      if (str) {
        setSearchClientIsFetching(true);
      }
      setSearchInput(str);
    },
    searchClientInput,
    searchClientOptions: options,
    isSearchClientFetching: isSearchClientFetching || isFetching,
  };
}
